.textPais {
  position: absolute; 
  bottom: auto; 
  top: auto;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: -1px 1px 18px black;
  user-select: none;
}

.itemPropuesta {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius:8px;
  width: 360px;
  height: 80px;
}

.contentText {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 290px;
  padding-right: 50px;
}

.titleProp {
  font-size: 25px;
  color: white;
  text-transform: uppercase;
  user-select: none;
}

.subtitleProp {
  margin-top: -3px;
  font-size: 16px;
  color: white;
  user-select: none;
}
