.colMovimientos {
  grid-column: span;
}

@media (min-width: 1750px) {
  .colMovimientos {
    grid-column: span 2;
  }
}


.tituloMov {
  font-size: 15px;
  font-weight: bold;
  line-height: 15px;
  color: #272727;
}

.contenidoMov {
  font-size: 15px;
  font-weight: bold;
  line-height: 15px;
  color: #7a7a7a;
}
