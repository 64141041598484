.colWallet {
  grid-column: col;
}

.containerWallet {
  flex: 1;
  padding: 32px;
}

.viewBtn {
  display: flex;
  height: 75px;
  width:100%;
  align-items: center;
  border-radius: 16px;
  margin-top: 24px;
  background-image: url('../../images/btn_wallet_bkg.jpg');
  border-width: 2px;
  border-color: '#ff0066';
  cursor: pointer;
}

.iconBtn {
  height: 50px;
  width: 50px;
  margin-right: 16px;
  margin-left: auto;
}

.btnText {
  line-height: 75px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: -1px 1px 18px gray;
  margin-left: 20px
};

/* DIALOGS */
.pageContainer {
  flex: 1;
}

.iconDialog {
  height: 60px;
  width: 60px;
}

.iconDialogSmall {  
  height: 30px;
  width: 30px;
}

.triangleIcon {
  position: absolute;
  justify-content: center;
  top: 10px;
  right: 4px;
  /*transform: [{ rotate: '180deg' }];*/
}

.errorTextWallet {
  margin-top: -6px;
  margin-left: 6px;
  font-size: 13px;
  color: red;
}

.customInput {
  width: 100%;
  background-color: transparent;
  color: black;
}
