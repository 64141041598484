.btnLogin {
  width: 220px;
  color: white;
  font-size: 12px;
  text-shadow: -1px 1px 15px black;  
  font-weight: bold;
  margin-top: 16px;
}

.textAviso {
  color: white;
  font-size: 10px;
  text-shadow: -1px 1px 4px black;  
  font-weight: bold;
  cursor: pointer;
}
