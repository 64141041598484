.contentLoader {
  position: fixed;
  display: flex;
  top:0;
  bottom: 0;
  left:0;
  right: 0;  
  /*background-color: $bg-color;*/
  opacity: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 18;
}

.title {
  color: gray;
  text-align: center; 
  user-select: none; 
}
