.contentHome {
  display: flex;
  height: 100vh;
  justify-content: flex-start;
  flex-direction: column;
  background: linear-gradient(to right, #FF00E0, #FFA3C2, #FFA3C2, #FFEE88, #FFEE88, #FFFF2B);
  overflow: auto;
}

.grid {  
  width: 100% - 60px;
  margin-top: 24px;
  margin-left: 10px;
  margin-right: 25px; 
  align-items: start;
  justify-content: flex-start;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2, [col] 50%);
}

@media (min-width: 1200px) {
  .grid {
    margin-right: 0;  
    grid-gap: 15px;
    grid-template-columns: repeat(3, [col] 33%);
  }
}

@media (min-width: 1750px) {
  .grid {  
    grid-gap: 15px;
    margin-left: 36px;
    margin-right: 0;
    grid-template-columns: repeat(5, [col] 20%);
  }
}
