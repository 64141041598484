.btnRegister {
  width: 220px;
  color: white;
  font-size: 12px;
  text-shadow: -1px 1px 15px black;  
  font-weight: bold;
  margin-top: 16px;
}

.inputRegister {
  width: 100%;  
}

.errorTextReg {
  margin-top: -6px;
  margin-left: 2px;
  font-size: 13px;
  color: red;
}
