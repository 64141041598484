.containerHeader {
  width: 100%;  
  z-index: 1;
  text-align: center;
}

.avatarHeader {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-top:5px;
  margin-bottom: 5px;
}

.logoHeader {  
  margin-top:10px;  
  margin-left: 74px;
  width: 150px;
  height: 50px;
}

.menuHeader {  
  margin-right: 16px;
  float: right;
}